import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FBPostActions from "./FBPostActions";

type FBPostTypes = {
  id: number;
  blogId: number;
  username: string;
  avatar?: string;
  postContent?: string;
  postTitle: string;
  imageUrl: string;
  timestamp: string;
  canShare?: boolean;
  commentsCount: number;
  likesCount: number;
  isAlreadyLiked: boolean;
};

const FBPost = ({
  id,
  blogId,
  username,
  avatar,
  postContent,
  postTitle,
  imageUrl,
  timestamp,
  canShare,
  commentsCount,
  likesCount,
  isAlreadyLiked,
}: FBPostTypes) => {
  const navigate = useNavigate();

  function routeToIndividualPost(): void {
    navigate(`/post/${id}`);
  }

  const defaultImage =
    "https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png";

  return (
    <Card sx={{ maxWidth: 600, margin: "0 auto", mb: 6, cursor: "pointer" }}>
      <CardHeader
        avatar={<Avatar src={avatar || defaultImage} alt={username} />}
        title={username}
        subheader={new Date(timestamp).toLocaleString()}
        subheaderTypographyProps={{ fontSize: "0.75em" }}
        titleTypographyProps={{ fontSize: "1em" }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Typography
          color="textPrimary"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1, // Show all lines if expanded
            WebkitBoxOrient: "vertical",
            cursor: "pointer",
            fontWeight: 600,
          }}
        >
          {postTitle}
        </Typography>
        {postContent && (
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: imageUrl ? 2 : 12, // Show all lines if expanded
              WebkitBoxOrient: "vertical",
            }}
          >
            {postContent}
          </Typography>
        )}
      </CardContent>
      {imageUrl && (
        <Box
          sx={{
            position: "relative",
            height: "auto",
            maxHeight: 400,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => routeToIndividualPost()}
        >
          {/* Blurred Background */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(20px)",
              zIndex: 1,
              opacity: 0.5,
              cursor: "pointer",
            }}
          />
          {/* Clear Image */}
          <img
            src={imageUrl}
            alt="Post"
            style={{
              width: "auto",
              position: "relative",
              maxWidth: "100%",
              zIndex: 2,
              maxHeight: 400,
              cursor: "pointer",
            }}
          />
        </Box>
      )}
      <FBPostActions
        id={id}
        blogId={blogId}
        canShare={!!canShare}
        commentsCount={commentsCount}
        likesCount={likesCount}
        isAlreadyLiked={isAlreadyLiked}
      />
    </Card>
  );
};

export default FBPost;
