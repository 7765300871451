import { IconButton, Typography, CardActions, Box } from "@mui/material";
import { Comment, Share } from "@mui/icons-material";
import Comments from "../SinglePostComponents/Comments";
import PostShareModal from "../PostShareModal";
import { useState } from "react";
import LikePost from "../LikePost";

type FBPostActionsProps = {
  canShare: boolean;
  id: number;
  blogId: number;
  commentsCount: number;
  likesCount: number;
  isAlreadyLiked: boolean;
};

export default function FBPostActions({
  canShare,
  id,
  blogId,
  commentsCount,
  likesCount,
  isAlreadyLiked,
}: FBPostActionsProps) {
  const [showComments, setShowComments] = useState(false);
  const [showModal, setModal] = useState(false);

  return (
    <>
      <CardActions disableSpacing sx={{ gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LikePost
            blogId={blogId}
            postId={id}
            likesCount={likesCount}
            isAlreadyLiked={isAlreadyLiked}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => setShowComments((s) => !s)}
            sx={{ color: "text.secondary" }}
          >
            <Comment />
          </IconButton>
          {commentsCount > 0 && (
            <Typography variant="body2">{commentsCount}</Typography>
          )}
        </Box>
        {canShare && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => setModal((s) => !s)}
              sx={{ color: "text.secondary" }}
            >
              <Share />
            </IconButton>
            <Typography variant="body2">Share</Typography>
          </Box>
        )}
      </CardActions>

      {showComments && <Comments postId={id} blogId={blogId} />}

      {showModal && (
        <PostShareModal
          postId={id}
          showModal={showModal}
          closeModel={() => setModal(false)}
        />
      )}
    </>
  );
}
