import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  mainDashboardContainer: {
    backgroundColor: theme.palette.background.default,
    minHeight: "90vh",
  },
  dahsboard: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  siteNewsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  siteNewsHead: {
    color: theme.palette.text.primary,
    fontSize: "16px",
  },
  emptyContainer: {
    color: theme.palette.text.primary,
  },
  tagBox: {
    height: "40px",
    paddingLeft: "15px",
    paddingRight: "15px",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    cursor: "pointer",
    marginRight: "10px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: theme.palette.text.primary,
      color: theme.palette.background.paper,
    },
  },
  tagContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 10,
    width: "100%",
    borderRadius: 5,
    color: theme.palette.text.primary,
  },
  tag: {
    textTransform: "lowercase",
  },
  loadingContainer: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
    paddingTop: "20px",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
  },
  loader: {
    color: theme.palette.text.primary,
  },
  newsBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  postLoadingContainer: {
    height: "70vh",
    paddingTop: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  postLoader: {
    color: theme.palette.text.primary,
    fontSize: "20px",
  },
  info: {
    width: "100%",
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 32,
    textAlign: "center",
  },
}));

export default useStyles;
