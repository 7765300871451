import React, { useContext, useState } from "react";
import { Box, Grid, Container } from "@mui/material";
import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import SettingsSectionItem from "../../components/Settings/SectionItem";
import ProfileItemsContainer from "../../components/Settings/ProfileItemsContainer";
import AccountItemsContainer from "../../components/Settings/AccountItemsContainer";
import BlogThemeItems from "../../components/Settings/BlogThemeItems";
import useStyles from "./styles";
import PageLoader from "../../components/PageLoader";
import { UserContext } from "../../Context";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CreateMobsPage from "../CreateMobsPage";
import MobsList from "../../components/MobsList";
import { BlogTypes } from "../../__generated__/graphql";

const SETTINGS = {
  PROFILE: "Profile",
  ACCOUNT: "Change Password",
  MOBLOGS: "Personal Profile",
  MOBS: "Groups",
  BLOGS: "Blogs",
  LOGS: "Logs",
  MOB_FORM: "Form",
  MOB_UPDATE: "Update Mob",
};

export default function SettingsPage(): JSX.Element {
  const [selectedValue, setSelectedValue] = useState(SETTINGS.PROFILE);
  const [prevPage, setPrevPage] = useState(SETTINGS.PROFILE);

  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const { data, loading } = useQuery(USER_PROFILE, {
    variables: { userId: Number(userId) },
    skip: !userId,
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const { classes } = useStyles();

  if (loading) return <PageLoader />;

  const settingsList = [
    {
      label: "User",
      list: [SETTINGS.PROFILE, SETTINGS.ACCOUNT],
    },
    {
      label: "Blogs List",
      list: [SETTINGS.MOBLOGS, SETTINGS.MOBS, SETTINGS.BLOGS, SETTINGS.LOGS],
    },
  ];

  return (
    <div className={classes.mainContainer}>
      <Container maxWidth="xl">
        <div className={classes.settings}>
          <div>
            <Grid container spacing={1}>
              {/* settings area */}
              <Grid item xs={12} sm={4} md={3} className={classes.navWrapper}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    height: { xs: "auto", sm: "93vh" },
                    px: 2,
                    py: 2,
                  }}
                >
                  {/* left side panel */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginBottom: { sm: 4 },
                      height: { xs: "auto", sm: "100vh" },
                      position: { xs: "relative", sm: "fixed" },
                    }}
                  >
                    {settingsList.map((set) => (
                      <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                        key={Math.random()}
                      >
                        <Box>
                          <p className={classes.settingsSectionName}>
                            {set.label}
                          </p>
                        </Box>
                        {set.list.map((li) => (
                          <SettingsSectionItem
                            key={li}
                            id={li}
                            name={li}
                            selectedValue={selectedValue}
                            setSelectedValue={(val) => setSelectedValue(val)}
                          />
                        ))}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              {/* main area */}
              {data?.profileByUserId && (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={9}
                  className={classes.sectionContainerBox}
                >
                  <Container maxWidth={"md"}>
                    <Box
                      sx={{ paddingLeft: { sm: 3, md: 5 }, paddingTop: "30px" }}
                    >
                      {selectedValue === SETTINGS.PROFILE && (
                        <ProfileItemsContainer
                          userDetails={data?.profileByUserId.profile}
                        />
                      )}
                      {selectedValue === SETTINGS.ACCOUNT && (
                        <AccountItemsContainer
                          userDetails={data?.profileByUserId}
                        />
                      )}
                      {selectedValue === SETTINGS.MOBLOGS && (
                        <Box>
                          <BlogThemeItems
                            blogDetails={
                              data.profileByUserId?.blogs?.[0] || null
                            }
                          />
                        </Box>
                      )}
                      {selectedValue === SETTINGS.MOBS && (
                        <Box>
                          <MobsList
                            isMobList
                            title="Groups"
                            blogType={BlogTypes.Mobs}
                            openCreateMob={() => {
                              setPrevPage(selectedValue);
                              setSelectedValue(SETTINGS.MOB_FORM);
                            }}
                          />
                        </Box>
                      )}
                      {selectedValue === SETTINGS.BLOGS && (
                        <Box>
                          <MobsList
                            title="Blogs"
                            blogType={BlogTypes.Blogs}
                            openCreateMob={() => {
                              setPrevPage(selectedValue);
                              setSelectedValue(SETTINGS.MOB_FORM);
                            }}
                          />
                        </Box>
                      )}
                      {selectedValue === SETTINGS.LOGS && (
                        <Box>
                          <MobsList
                            blogType={BlogTypes.Logs}
                            title="Logs"
                            openCreateMob={() => {
                              setPrevPage(selectedValue);
                              setSelectedValue(SETTINGS.MOB_FORM);
                            }}
                          />
                        </Box>
                      )}
                      {selectedValue === SETTINGS.MOB_FORM && (
                        <CreateMobsPage
                          goBack={() => setSelectedValue(prevPage)}
                        />
                      )}
                    </Box>
                  </Container>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
}

const USER_PROFILE = gql(`
  query ProfileByUserIdData($userId:Int!){
      profileByUserId(userId:$userId){
        userId
        email
        userName
        privacyLevel
        taggable
        mentionable
        createdAt
        profile{
          userName
          avatarImage
          createdAt
          dob
          firstName
          lastName
          updatedAt
          userId
        }
        siteSettings{
          type
          value
        } 
        blogs{
          id
          name
          about
          userId
          blogType
          theme
          privacyLevel
          privacyLevelInfo{
            id
            name
          }
          themeInfo{
            id
            name
          }
          themeMediaInfo{
            blogId
            themeId
            url
          }
          followingCount
          friendsCount
          followersCount 
          createdAt
        } 
      }
    }
`);
