import React from "react";
import { Box } from "@mui/material";
import { AiOutlineComment } from "react-icons/ai";

import useStyles from "./styles";
import LikePost from "../../components/LikePost";

interface CardButtonsProps {
  id: any;
  userBlogId: any;
  post: any;
  commentsCount: number;
  tabIndex: number;
  routeToIndividualPost: () => void;
  isAlreadyliked: boolean;
}

export default function CardButtons({
  id,
  post,
  commentsCount,
  routeToIndividualPost,
  isAlreadyliked,
}: CardButtonsProps): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.iconContainer}>
      <LikePost
        postId={post.id}
        blogId={post?.blogId}
        likesCount={post?.likes?.length || 0}
        isAlreadyLiked={isAlreadyliked}
        // postAllLikes={post?.likes || []}
      />

      <Box className={classes.iconWrapper} onClick={routeToIndividualPost}>
        {`${commentsCount} `}
        <AiOutlineComment size={18} />
      </Box>
    </div>
  );
}
