import { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useLazyQuery } from "@apollo/client";

// import PostContainer from "../../containers/PostContainer";
// import NewsComponent from '../../Components/NewsComponent';
import useStlyes from "./styles";
import "./styles.css";
import { NAVBAR_QUERY } from "../../utils/constants";
import { gql } from "../../__generated__";
import { PostSearchPageEnum } from "../../__generated__/graphql";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../../components/PageLoader";
import FBPost from "../../components/FBPost/FBPost";

export default function Dashboard(): JSX.Element {
  const PER_PAGE = 15;
  const { classes } = useStlyes();

  const [getPosts, { loading, data, fetchMore }] = useLazyQuery(POST_LIST, {
    fetchPolicy: "network-only",
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const handleScroll = () => {
    const paginationDetails = data?.searchPost?.paginationDetails;
    const lastPage = paginationDetails?.lastPage;
    const currentPage = paginationDetails?.currentPage;
    const isLastPage = lastPage === currentPage;

    const isScrollToBottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 2;
    if (data && !isLastPage && isScrollToBottom) {
      fetchMore({
        variables: {
          pagination: {
            nextPage: (currentPage || 1) + 1,
            perPage: PER_PAGE,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const prevData = prev.searchPost?.data || [];
          const newData = fetchMoreResult.searchPost?.data || [];

          // update states
          return {
            searchPost: {
              ...fetchMoreResult.searchPost,
              data: [...prevData, ...newData],
            },
          };
        },
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const value = query.get("query") || NAVBAR_QUERY.LATEST;
    let page = PostSearchPageEnum.Following;

    if (NAVBAR_QUERY.FOLLOWING === value) {
      page = PostSearchPageEnum.Following;
    } else if (NAVBAR_QUERY.FRIENDS === value) {
      page = PostSearchPageEnum.Friends;
    } else if (NAVBAR_QUERY.HIGHTLIGHTS === value) {
      page = PostSearchPageEnum.Highlights;
    } else if (NAVBAR_QUERY.DISCUSSIONS === value) {
      page = PostSearchPageEnum.Discussions;
    } else if (NAVBAR_QUERY.LATEST === value) {
      page = PostSearchPageEnum.Latest;
    }
    getPosts({
      variables: {
        searchInfo: { page },
        pagination: { perPage: PER_PAGE, nextPage: 1 },
      },
    });
  }, [search, getPosts]);

  if (loading) return <PageLoader />;
  if (!loading && !data)
    return (
      <Box className={classes.info}>
        Page did not load properly.
        <br /> Please refresh the page.
      </Box>
    );

  if (data) {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.mainDashboardContainer}>
          <div className={classes.dahsboard}>
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                {data.searchPost?.data?.length !== 0 &&
                  data.searchPost?.data.map((singlePost) => {
                    const isShared = !!singlePost?.isShared;
                    const url = isShared
                      ? singlePost?.originalPost?.media?.[0]?.fileUrl
                      : singlePost?.media?.[0]?.fileUrl;

                    if (!singlePost) return null;

                    return (
                      <FBPost
                        id={singlePost?.id}
                        imageUrl={url || ""}
                        blogId={singlePost.blogId}
                        postContent={singlePost?.description || ""}
                        postTitle={singlePost.title}
                        username={singlePost.profile?.userName || ""}
                        timestamp={singlePost?.createdAt}
                        avatar={singlePost?.profile?.avatarImage || ""}
                        canShare={!!singlePost.allowReshare}
                        commentsCount={singlePost.comments?.length || 0}
                        likesCount={singlePost.likes?.length || 0}
                        isAlreadyLiked={!!singlePost.isLike}
                      />
                    );
                  })}

                {data?.searchPost?.data?.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: 100,
                      minHeight: "100vh",
                    }}
                    className={classes.emptyContainer}
                  >
                    <h2>No posts to show </h2>
                  </div>
                )}
              </Box>
            </Box>
          </div>
        </div>
      </Container>
    );
  }
  return <></>;
}

const POST_LIST = gql(`
  query SearchPost($searchInfo: PostsSearchInput!, $pagination:Pagination) {
    searchPost(searchInfo: $searchInfo,pagination:$pagination) {
      paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        allowReshare
        blogId
        flagged
        isShared
        isLike
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        highlighted{
          postId
        }
        comments{
         id
        }
         likes{
          postId
          userId
         }
        createdAt
        description
        hidden
        id
        media {
          id
          fileUrl
        }
        profile{
          avatarImage
          userName
        }
        privacyLevel
        title
        userId
      }
    }
  }`);
