import { useContext, useState } from "react";
import moment from "moment";

import { THEMES_LIST } from "../../utils/constants";
import { Account, Blog } from "../../__generated__/graphql";
import MassonaryTheme from "./massonaryTheme";
import WallpaperTheme from "./wallpaperTheme";
import CoverTheme from "./coverTheme";
import ProfileDetails from "../../components/SinglePostComponents/ProfileDetails";
import MainSection from "../../components/ProfileComponents/MainSection";
import ActivitySection from "../../components/ProfileComponents/ActivitySection";
import { UserContext } from "../../Context";
import { ProfileComponentProps } from "../../types/commom";
import TabsList from "../../components/TabsList";

interface ProfileContainerProps {
  userDetails: Pick<Account, "profile" | "userId">;
  blogId: number;
  blogDetails: Pick<
    Blog,
    "profile" | "createdAt" | "name" | "about" | "id" | "theme"
  >;
}

const tabs = ["Posts", "Likes", "Shares", "Comments"];

export default function ProfileContainer({
  blogId,
  userDetails,
  blogDetails,
}: ProfileContainerProps) {
  const userContext = useContext(UserContext);

  const pageOwner = userContext?.user?.userId === blogDetails?.profile?.userId;
  const [activeTab, setActiveTab] = useState(0);

  const renderTab = () => {
    return (
      <TabsList
        tabs={tabs}
        setActiveTab={(id) => setActiveTab(id)}
        activeTab={activeTab}
      />
    );
  };

  const renderProfile = (props?: ProfileComponentProps) => {
    const formatDate = moment(blogDetails?.createdAt).utc().format("MMMM YYYY");

    return (
      <ProfileDetails
        userDetails={userDetails.profile}
        blogDetails={blogDetails}
        joinedDate={`Joined ${formatDate}`}
        isCenter={!props?.center}
      />
    );
  };

  const renderMainComponent = () => {
    return (
      <MainSection
        blogId={blogId}
        blogDetails={blogDetails}
        userDetails={userDetails}
        activeTabId={activeTab}
      />
    );
  };

  const renderActivityComponent = () => {
    return (
      <ActivitySection
        pageOwner={pageOwner}
        userId={userDetails.userId}
        blogId={blogId}
      />
    );
  };
  const activeTheme = blogDetails.theme;
  console.log({ activeTheme });

  if (activeTheme === THEMES_LIST.MASONARY) {
    return (
      <div>
        <MassonaryTheme
          userDetails={userDetails}
          blogDetails={blogDetails}
          profileComponent={renderProfile}
          mainComponent={renderMainComponent}
          acitivityComponent={renderActivityComponent}
        />
      </div>
    );
  }
  if (activeTheme === THEMES_LIST.WALLPAPER) {
    return (
      <div>
        <WallpaperTheme
          userDetails={userDetails}
          blogDetails={blogDetails}
          profileComponent={renderProfile}
          mainComponent={renderMainComponent}
          acitivityComponent={renderActivityComponent}
          tabComponent={renderTab}
        />
      </div>
    );
  }

  if (activeTheme === THEMES_LIST.TIMELINE) {
    return (
      <div>
        <CoverTheme
          userDetails={userDetails}
          blogDetails={blogDetails}
          profileComponent={renderProfile}
          mainComponent={renderMainComponent}
          acitivityComponent={renderActivityComponent}
          tabComponent={renderTab}
        />
      </div>
    );
  }

  return <div>Theme not found</div>;
}
