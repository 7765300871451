import { useContext, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import Comments from "./Comments";
import { Blog, Profile } from "../../__generated__/graphql";
import LikePost from "../LikePost";
import PostShareModal from "../PostShareModal";
import CustomButton from "../CutomButton";
import ReactModal from "../ReactModal";
import PostForm from "../PostForm";
import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";

import useStyles from "./styles";
import { BsTrash } from "react-icons/bs";
import AdminCardActions from "../../containers/PostCardContainer/adminActions";
import PostDeleteModal from "./PostDeleteModal";
import { postEditStateToApi } from "../../utils/postHelper";
import { PostViewPageTypes } from "../../types/postTypes";
import { UserContext } from "../../Context";
import { Comment, Share } from "@mui/icons-material";

interface SinglePostComponentsProps {
  id: number;
  postDetails: PostViewPageTypes;
  userDetails: Profile;
  blogDetails: Pick<Blog, "id" | "name" | "userId">;
  pageOwner: boolean;
  joinedDate: string;
  isWallpaperTheme: boolean;
  isPersonalPage: boolean;
  refetchPost: () => void;
}

type PostFormTypes = {
  title: string;
  text: string;
  file: File | null;
  allowReshare: boolean;
  privacyLevel: number;
};

export default function SinglePostComponents({
  postDetails,
  userDetails,
  pageOwner,
  id,
  blogDetails,
  isWallpaperTheme,
  isPersonalPage,
  refetchPost,
}: SinglePostComponentsProps): JSX.Element {
  const postCreatedDate = moment(
    postDetails.originalPost?.createdAt || postDetails?.createdAt
  ).format("MMMM DD YYYY  hh:mm:ss a");
  const postSharedDate = moment(postDetails.originalPost?.createdAt).format(
    "MMMM DD YYYY  hh:mm:ss a"
  );
  const navigate = useNavigate();

  const { classes: s } = useStyles();
  const [showModal, setModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);

  const [updatePost, { loading }] = useMutation(UPDATE_POST, {
    onCompleted: () => {
      toast.success("Post updated successfully");
      setEditModal(false);
      refetchPost();
    },
    onError: (error) => toast.error(graphqlErrorToMsg(error)),
  });

  const cn = [s.postWrapper];
  if (isWallpaperTheme) {
    cn.push(s.wallpaper);
  }

  function gotToRoute(): void {
    if (userDetails?.userId) {
      navigate(`/user/${userDetails.userId}`);
    }
  }

  const imageUrl = !postDetails.isShared
    ? postDetails.media?.[0]?.fileUrl
    : postDetails.originalPost?.media?.[0]?.fileUrl;

  const { user } = useContext(UserContext);

  const isAdmin = user?.isAdmin;
  const isModerator = user?.isModerator;

  const showPostEdit = isAdmin || isModerator;

  return (
    <Box sx={{ mb: 10 }}>
      <Container maxWidth="lg">
        <Box>
          {!postDetails.isShared && (
            <Box
              className={isWallpaperTheme ? s.shared : s.sharedCover}
              sx={{ borderRadius: 2, my: 2 }}
            >
              <Box
                onClick={() => {
                  if (isPersonalPage) {
                    navigate(`/user/${postDetails.userId}`);
                  } else {
                    navigate(`/moblog/${blogDetails.id}`);
                  }
                }}
                sx={{ background: "none" }}
              >
                <Typography
                  color={"text.primary"}
                  fontSize={24}
                  fontWeight={600}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    py: 3,
                    px: 2,
                  }}
                >
                  {blogDetails.name}
                </Typography>
              </Box>
            </Box>
          )}

          {postDetails.isShared && (
            <Box
              className={isWallpaperTheme ? s.shared : s.sharedCover}
              sx={{ borderRadius: 2, my: 2 }}
            >
              <Typography color={"text.primary"} fontSize={24} fontWeight={600}>
                {postDetails.shareText}
              </Typography>
              <Typography>
                Shared by{" "}
                <span
                  className={s.postedBy}
                  onClick={() =>
                    navigate(`/user/${postDetails.sharedUserProfile?.userId}`)
                  }
                >
                  @{postDetails.sharedUserProfile?.userName}
                </span>
                <span className={s.shareDate}>{postSharedDate}</span>
              </Typography>
            </Box>
          )}
          {/* </div>

          {/* post main section */}
          <div className={cn.join(" ")}>
            {pageOwner && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 10,
                }}
              >
                <CustomButton label="Edit" onClick={() => setEditModal(true)} />
                <Stack
                  className={s.deleteButton}
                  onClick={() => setDeleteModal(true)}
                >
                  <SvgIcon>
                    <BsTrash />
                  </SvgIcon>
                </Stack>
              </Box>
            )}
            <div className={s.imageWrapper}>
              <Box className={s.cover}>
                {postDetails && showPostEdit && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      position: "absolute",
                      top: -1,
                      left: 8,
                      zIndex: 10,
                    }}
                  >
                    <AdminCardActions
                      postId={postDetails.id}
                      blogId={postDetails.blogId}
                      refetch={() => {}}
                      flagged={!!postDetails.flagged}
                      highlighted={!!postDetails.highlighted?.postId}
                    />
                  </Box>
                )}
                <img src={imageUrl} className={s.coverImage} alt={"cover"} />
              </Box>
            </div>
            <Box className={s.postInfo}>
              {postDetails.isShared && (
                <div
                  className={s.blogTitle}
                  onClick={() => navigate(`/user/${blogDetails.userId}`)}
                >
                  {postDetails?.originalPost?.blog?.name}
                </div>
              )}
              <div className={s.postTitle}>{postDetails?.title}</div>
              <div className={s.postDescription}>
                {postDetails?.description}
              </div>
              <div
                className={s.postedBy}
                onClick={() => gotToRoute()}
              >{`@${userDetails.userName}`}</div>
              <div className={s.tagContainer}>
                {/* {postDetails?.hashTags.map((item: any) => (
                  <p className={s.tagText}>#{item}</p>
                ))} */}
              </div>
              <div className={s.infoContainer}>
                <div>{postCreatedDate}</div>
              </div>
              <div className={s.actionButtons}>
                <LikePost
                  postId={postDetails?.id}
                  blogId={postDetails?.blogId}
                  likesCount={postDetails?.likes?.length || 0}
                  isAlreadyLiked={!!postDetails?.isLike}
                  // postAllLikes={postDetails?.likes || []}
                />
                <div className={s.actionGroup}>
                  <IconButton sx={{ color: "text.secondary" }}>
                    <Comment />
                  </IconButton>
                  <span>{postDetails?.comments?.length || 0}</span>
                </div>
                {postDetails.allowReshare && (
                  <div className={s.actionGroup}>
                    <IconButton
                      onClick={() => setModal((s) => !s)}
                      sx={{ color: "text.secondary" }}
                    >
                      <Share />
                    </IconButton>
                    {showModal && (
                      <PostShareModal
                        postId={id}
                        showModal={showModal}
                        closeModel={() => setModal(false)}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* activities */}
              {/* {(pageOwner || isAdmin) && <Activity postId={id} />} */}

              {/* comment Section */}
              <div className={s.commetsWrapper}>
                <Comments postId={id} blogId={blogDetails?.id} />
              </div>
            </Box>
          </div>
        </Box>
      </Container>

      {showDeleteModal && (
        <PostDeleteModal
          isOpen={showDeleteModal}
          id={postDetails.id}
          handleClose={() => setDeleteModal(false)}
          handleComplete={() => gotToRoute()}
        />
      )}

      {showEditModal && (
        <ReactModal
          isOpen={showEditModal}
          handleClose={() => setEditModal(false)}
        >
          <PostForm
            isUpdate
            isPersonalBlogPost={isPersonalPage}
            postId={postDetails.id}
            handleSubmit={(values: PostFormTypes) => {
              if (postDetails) {
                const apiReq = postEditStateToApi({
                  states: values,
                  blogId: blogDetails.id,
                  postId: postDetails.id,
                });
                updatePost(apiReq);
              }
            }}
            handleCancel={() => navigate(-1)}
            loading={loading}
          />
        </ReactModal>
      )}
    </Box>
  );
}

const UPDATE_POST = gql(`
  mutation updatePost($info: UpdatePostInputs!, $file: Upload) {
    updatePost(info: $info, file: $file) {
      id
      userId
      blogId
      title
      description
      privacyLevel
      hidden
      isShared
      shareText
      flagged
      media{
        id
        postId
        fileUrl
      }
      originalPost{
        id
        userId
        blogId
        title
        description
        privacyLevel
        isShared
        blog{
          id
          name
        }
        media{
          id
          postId
          fileUrl
        }
      }
    }
  }`);
