import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: 25,
    marginBottom: 25,
    maxHeight: 350,
    overflowY: "auto",
  },
  title: {
    backgroundColor: "rgba(33, 158, 188, 0.4)",
    padding: "10px 8px",
    borderRadius: "5px 5px 0 0",
    fontSize: 20,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
  },
  userName: {
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  replyBox: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    fontSize: "12px",
  },
  replyButton: {
    color: "#219EBC",
  },
}));

export default useStyles;
