import { Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import useStyles from "../pages/ProfilePage/styles";
import ProfileDetails from "../components/SinglePostComponents/ProfileDetails";
import ActivitySection from "../components/ProfileComponents/ActivitySection";
import { useQuery } from "@apollo/client";
import PageLoader from "../components/PageLoader";
import { gql } from "../__generated__";
import React from "react";
import moment from "moment";

type ProfilePageLayoutTypes = {
  children: React.ReactElement;
};
export default function ProfilePageLayout({
  children,
}: ProfilePageLayoutTypes) {
  const { data, loading } = useQuery(LOGGED_USER_PROFILE, {
    fetchPolicy: "network-only",
  });

  const { classes: s } = useStyles();

  if (loading) {
    return <PageLoader />;
  }
  if (data?.loggedUserProfile && data?.loggedUserProfile?.blogs?.[0]?.id) {
    const blogId = data.loggedUserProfile?.blogs?.[0]?.id;
    const blogDetails = data.loggedUserProfile.blogs[0];
    const userDetails = data.loggedUserProfile;
    const formatDate = moment(blogDetails?.createdAt).utc().format("MMMM YYYY");
    return (
      <div>
        <div className={s.root}>
          <Container maxWidth="xl" sx={{ my: 8 }}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12}>
                <div className={s.wrapper}>
                  <Box>
                    <Grid container spacing={{ xs: 2, sm: 4 }}>
                      <Grid
                        item
                        md={3}
                        sx={{
                          display: {
                            xs: "none",
                            md: "flex",
                          },
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <ProfileDetails
                            userDetails={userDetails.profile}
                            blogDetails={blogDetails}
                            joinedDate={`Joined ${formatDate}`}
                            isCenter
                          />
                        </Box>
                      </Grid>

                      <Grid item md={6} xs={12} sx={{ overflowY: "auto" }}>
                        {children}
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        sx={{
                          display: { xs: "none", md: "flex" },
                          width: "100%",
                        }}
                      >
                        <ActivitySection
                          pageOwner
                          userId={userDetails.userId}
                          blogId={blogId}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
  return <div>Profile not found</div>;
}

const LOGGED_USER_PROFILE = gql(`
    query LoggedUserProfile{
        loggedUserProfile{
          userId
          email
          userName
          privacyLevel
          taggable
          mentionable
          createdAt
          profile{
            userId
            firstName
            lastName
            userName
            createdAt
            avatarImage
          }
          siteSettings{
            type
            value
          } 
          blogs{
            id
            name
            title
            about
            userId
            blogType
            theme
            privacyLevel
            privacyLevelInfo{
              id
              name
            }
            themeInfo{
              id
              name
            }
            themeMediaInfo{
              blogId
              themeId
              url
            }
            followingCount
            friendsCount
            followersCount 
            createdAt
          } 
        }
      }
  `);
