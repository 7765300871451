import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";

export default function Search(): JSX.Element {
  const { classes: s } = useStyles();
  const navigate = useNavigate();

  const [state, setstate] = useState<string>("");

  function submitSearch(): void {
    navigate(`/posts/search/${state}`);
  }

  return (
    <form
      className={s.form}
      onSubmit={(e) => {
        e.preventDefault();
        submitSearch();
      }}
    >
      <Box className={s.root}>
        <IconButton size="large" color="primary" className={s.iconButton}>
          <AiOutlineSearch />
        </IconButton>
        <input
          type="text"
          value={state}
          onChange={(e) => setstate(e.target.value)}
          placeholder="Search in Social Cluster"
          className={s.input}
        />
      </Box>
    </form>
  );
}
