import { useState } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../components/PageLoader";
import { PostsTable } from "../adminComponents/Post/PostsTable";
import PostEditPage from "../adminComponents/Post/PostEdit";
import { PostSearch } from "../adminComponents/Post/PostSearch";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../utils/graphqlErrorToMsg";
import PostView from "../adminComponents/Post/PostView";
import PostDeleteModal from "../components/SinglePostComponents/PostDeleteModal";
import { useParams } from "react-router-dom";

const PostsPage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [postEditId, setPostId] = useState<null | number>(null);
  const [postViewId, setPostViewId] = useState<null | number>(null);
  const [postDeleteId, setPostDeleteId] = useState<null | number>(null);

  const params = useParams();
  const blogId = params?.blogId ? Number(params.blogId) : null;

  const {
    data: postList,
    loading,
    refetch,
  } = useQuery(POST_SEARCH, {
    notifyOnNetworkStatusChange: true,
    variables: {
      info: { title: "", blogId },
      pagination: { nextPage: page, perPage: rowsPerPage },
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const handlePageChange = (event: any, value: number) => {
    setPage(value + 1);
    refetch({
      pagination: { nextPage: value + 1, perPage: rowsPerPage },
    });
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
    refetch({
      pagination: { nextPage: 1, perPage: event.target.value },
    });
  };

  if (postEditId) {
    return (
      <>
        <PostEditPage
          handleClose={() => setPostId(null)}
          postId={postEditId}
          refetchPostList={() => refetch()}
        />
      </>
    );
  }
  if (postViewId) {
    return (
      <PostView postViewId={postViewId} goBack={() => setPostViewId(null)} />
    );
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Posts List</Typography>
              </Stack>
            </Stack>
            <PostSearch
              handleSearch={(search) =>
                refetch({
                  info: {
                    title: search.title,
                    flagged: search.flagged,
                    highlighted: search.highlighted,
                    privacyLevel: Number(search.privacyLevel) || null,
                    blogId,
                  },
                  pagination: {
                    nextPage: 1,
                    perPage: rowsPerPage,
                  },
                })
              }
            />
            {loading && <PageLoader />}
            {postList?.postListAdmin && (
              <PostsTable
                count={postList.postListAdmin.paginationDetails?.totalRecords}
                items={postList.postListAdmin.data}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={postList.postListAdmin.paginationDetails?.currentPage}
                rowsPerPage={rowsPerPage}
                setEditId={setPostId}
                refetchPost={() => refetch()}
                setPostViewId={setPostViewId}
                setPostDeleteId={setPostDeleteId}
              />
            )}
          </Stack>
          {postDeleteId && (
            <PostDeleteModal
              id={postDeleteId}
              isOpen={!!postDeleteId}
              handleClose={() => setPostDeleteId(null)}
              handleComplete={() => {
                setPostDeleteId(null);
                refetch();
              }}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default PostsPage;

const POST_SEARCH = gql(`
  query PostListAdmin($pagination:Pagination,$info:AdminPostSearchInput!){
    postListAdmin(info:$info,pagination:$pagination){
      paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        id
        userId
        blogId
        title
        description
        hidden
        flagged
        createdAt
        highlighted{
          postId
        }
        profile{
          userId
          firstName
          lastName
        }
        isShared
        isLike
        shareText
        media{
          id
          fileUrl
        }
        originalPost{
          id
          userId
          blogId
          title
          description
          privacyLevel
          isShared
          media{
            id
            postId
            fileUrl
          }
        }
      }
    }
  }
`);
