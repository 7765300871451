import { useState } from "react";
import useStyles from "./styles";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { USERACTIONS } from "../../utils/constants";
import { IconButton, Typography } from "@mui/material";
import { Favorite } from "@mui/icons-material";

interface LikePostProps {
  postId: number;
  blogId: number;
  likesCount: number;
  isAlreadyLiked: boolean;
}
export default function LikePost({
  postId,
  blogId,
  likesCount,
  isAlreadyLiked,
}: LikePostProps) {
  const [postLiked, setPostLiked] = useState(isAlreadyLiked);
  const [count, setCount] = useState(likesCount);
  const { classes: s } = useStyles();

  const [addPostUserAction] = useMutation(POST_LIKE, {
    onError: () => setPostLiked((s) => !s),
    onCompleted: () => {
      const newCount = postLiked ? count - 1 : count + 1;
      setCount(newCount);
      setPostLiked((s) => !s);
    },
  });

  const handleLikes = () => {
    if (blogId && postId) {
      addPostUserAction({
        variables: {
          info: {
            blogId,
            type: USERACTIONS.LIKED,
            postId,
          },
        },
      });
    }
  };

  return (
    <div className={s.root}>
      {!postLiked ? (
        <IconButton sx={{ cursor: "pointer" }} onClick={() => handleLikes()}>
          <Favorite />
        </IconButton>
      ) : (
        <IconButton
          sx={{ color: "red", cursor: "pointer" }}
          onClick={() => handleLikes()}
        >
          <Favorite />
        </IconButton>
      )}
      {count > 0 && <Typography variant="body2">{count}</Typography>}
    </div>
  );
}

const POST_LIKE = gql(`
    mutation AddPostUserAction($info:PostActionInput){
      addPostUserAction(info:$info){
        message
      }
    }
`);
