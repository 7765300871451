import { useContext, useState } from "react";
import { Avatar, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import commentTimeCalculate from "../../functions/CommentTimeCalculation";
import useStyles from "./styles";
import { gql } from "../../__generated__";
import { UserContext } from "../../Context";
import CommentForm from "./CommentForm";

interface CommentsProps {
  comment: any;
  refetchComments: () => void;
}

export default function Comments({
  comment,
  refetchComments,
}: CommentsProps): JSX.Element {
  // hooks
  const { classes: s } = useStyles();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onCompleted: () => refetchComments(),
  });

  const [isEditable, setEdit] = useState(false);

  const [editComment] = useMutation(EDIT_COMMENT, {
    onCompleted: () => {
      setEdit(false);
      refetchComments();
    },
  });

  const userId = userContext?.user?.userId;

  function gotToRoute(): void {
    if (comment?.profile?.userName) {
      navigate(`/user/${comment?.profile?.userId}`);
    }
  }

  function submitCommentReply(value: string): void {
    if (comment.id) {
      editComment({
        variables: {
          info: {
            comment: value,
            id: comment.id,
          },
        },
      });
    }
  }

  return (
    <div>
      <Grid container key={comment.id} sx={{ padding: "15px" }}>
        <Grid item>
          <Avatar src={comment?.profile?.avatarImage} />
        </Grid>
        <Grid item xs style={{ paddingLeft: "10px" }}>
          <Box
            className={s.userName}
            onClick={() => gotToRoute()}
          >{`${comment?.profile?.firstName} ${comment?.profile?.lastName}`}</Box>
          {isEditable ? (
            <CommentForm
              text={comment.comment}
              isEdit
              commentId={comment.id}
              submitComment={submitCommentReply}
            />
          ) : (
            <Box>{comment?.comment}</Box>
          )}
          <Box className={s.replyBox}>
            {`${commentTimeCalculate(comment?.createdAt)}`}{" "}
            {userId === comment?.profile?.userId && (
              <>
                <div
                  className={s.replyButton}
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    setEdit((s) => !s);
                  }}
                >
                  {isEditable ? "Cancel" : "Edit"}
                </div>
                {!isEditable && (
                  <div
                    className={s.removeButton}
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      deleteComment({ variables: { commentId: comment.id } });
                    }}
                  >
                    Remove
                  </div>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* <Box>
        {subComments?.map((item: any) => (
          <div className={s.subCommentWrapper} key={item.id}>
            {renderComment(item)}
          </div>
        ))}
      </Box> */}
    </div>
  );
}

const DELETE_COMMENT = gql(`mutation deleteComment($commentId:Int!){
  deleteComment(commentId:$commentId){
    message
  }
}`);

const EDIT_COMMENT = gql(`mutation EditPostComment($info:EditCommentInput!){
  editPostComment(info:$info){
    id
    postId
    blogId
    userId
    comment
  }
}`);
