import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import ROUTES from "../../utils/routes";
import PageLoader from "../../components/PageLoader";
import ReactModal from "../../components/ReactModal";
import { Grid, colors } from "@mui/material";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CustomButton from "../../components/CutomButton";

export default function MobsJoiningPage() {
  const navigate = useNavigate();

  const [joinToMob, { loading, error }] = useMutation(JOIN_TO_MOB, {
    onCompleted: (data) => {
      navigate(`${ROUTES.MOBS_PAGE}/${data.joinToBlogUsingLink.blogId}`);
    },
  });
  const params = useParams();
  useEffect(() => {
    if (params.uuid) {
      joinToMob({ variables: { uuid: params.uuid } });
    }
  }, [params.uuid, joinToMob]);

  if (loading) return <PageLoader />;

  return (
    <ReactModal
      isOpen={!!error}
      handleClose={() => navigate("/dashboard")}
      title="Mob joining failed"
      modelWidth={{ xs: "80%", sm: "50%", md: "40%", lg: "30%" }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ py: 2, color: colors.red[400] }}>
          {graphqlErrorToMsg(error)}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            color: colors.red[400],
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            variant="outlined"
            label="Go to Homepage"
            onClick={() => navigate(ROUTES.DASHBOARD)}
          />
        </Grid>
      </Grid>
    </ReactModal>
  );
}

const JOIN_TO_MOB = gql(`mutation JoinToBlogUsingLink($uuid:String!){
    joinToBlogUsingLink(uuid:$uuid){
      blogId
    }
  }`);
